'use client';

import { InputProps } from '@av/ui/form';
import { cn } from '@av/utils';
import * as React from 'react';

const FloatingLabel = React.forwardRef<
  HTMLDivElement,
  InputProps & { label: string; name: string; filled?: boolean }
>(({ className, name, label, children, filled, ...props }, ref) => {
  return (
    <div className="relative" ref={ref} {...props}>
      {children}
      <label
        htmlFor={name}
        className={cn(
          'absolute pointer-events-none font-normal text-base text-muted-fg duration-300 transform top-4 z-50 origin-[0] start-5',
          className,
          filled !== undefined && filled
            ? '-translate-y-3.5 scale-[0.7] text-primary'
            : 'scale-100 translate-y-0',
          filled === undefined &&
            '-translate-y-3.5 scale-[0.7] rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-primary text-primary peer-focus:dark:text-primary peer-placeholder-shown:text-muted-fg peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-[0.7] peer-focus:-translate-y-3.5'
        )}
      >
        {label}
      </label>
    </div>
  );
});
FloatingLabel.displayName = 'FloatingLabel';

export { FloatingLabel };
