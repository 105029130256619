import React from 'react';
import { useFormContext } from 'react-hook-form';

import { FormControl, FormField, FormItem, FormMessage } from '@av/ui/form';

import ReCAPTCHA from 'react-google-recaptcha';
import { cn } from '@av/utils';
import { validateCaptchaToken } from '@av/validators/validate-captcha-token';

interface FormData {
  validatedCaptcha: string;
}

interface Props {
  loading: boolean;
  className?: string;
}

const recaptchaRef = React.createRef<ReCAPTCHA>();

const CaptchaForm: React.FC<Props> = ({ loading, className }) => {
  const { control } = useFormContext<FormData>();

  return (
    <FormField
      control={control}
      name="validatedCaptcha"
      render={({ field }) => (
        <FormItem
          className={cn(
            'flex w-full flex-col items-center justify-center rounded-md bg-background p-3 text-center text-xs font-light text-primary',
            loading ? 'pointer-events-none opacity-70' : '',
            className
          )}
        >
          <FormControl>
            <ReCAPTCHA
              hl="nl"
              ref={recaptchaRef}
              sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY!}
              onChange={async (token) => {
                if (token) {
                  const result = await validateCaptchaToken(token);
                  field.onChange(result);
                }
              }}
              onExpired={() => {
                recaptchaRef.current?.reset();
                field.onChange('');
              }}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default CaptchaForm;
