'use client';

import React, { useTransition } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';

import { Separator } from '@av/ui/form';

import { LoginForm, loginFormSchema } from '@av/validators';

import { Form, FormControl, FormField, FormItem, FormMessage } from '@av/ui/form';
import { Button } from '@av/ui/button';
import { ArrowRight } from 'lucide-react';
import AuthWrapper from '../components/auth-wrapper';
import { Loader } from '@/components/loading/loading';
import { loginUser } from '../components/actions';
import { FloatingLabelInput } from '@/components/form/counter/floating-label-input';
import { useRouter, useSearchParams } from 'next/navigation';
import { useSession } from 'next-auth/react';
import Link from 'next/link';
import { toast } from 'react-hot-toast';
import CaptchaForm from '../components/captcha-form';
import { useProductSearch } from '@/app/components/context/product-search-context';

export default function LoginPage() {
  const [loading, startTransition] = useTransition();
  const searchParams = useSearchParams();
  const callbackUrl = searchParams.get('callbackUrl');
  const router = useRouter();
  const { update, data: session } = useSession();
  const { refetchOrderLists } = useProductSearch();
  const form = useForm<LoginForm>({
    resolver: zodResolver(loginFormSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = async (data: LoginForm) => {
    startTransition(async () => {
      try {
        await loginUser({ ...data, id: session?.user.uuid });
        refetchOrderLists?.();
        toast.success('Succesvol ingelogd!');
        update();
        router.refresh();
        router.push(callbackUrl || '/account/overzicht');
      } catch (e) {
        form.setError('email', { message: ' ' });
        form.setError('password', {
          message: 'Geen bevestigd account gevonden voor bovenstaande gegevens',
        });
      }
    });
  };

  return (
    <AuthWrapper title="Inloggen" description="Vul je e-mailadres en wachtwoord in.">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="flex w-full flex-col space-y-2">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <FloatingLabelInput
                    disabled={loading}
                    label="E-mailadres"
                    type="email"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <FloatingLabelInput
                    disabled={loading}
                    label="Wachtwoord"
                    type="password"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <CaptchaForm loading={loading} />
          <Button className="w-full" type="submit" aria-disabled={loading} disabled={loading}>
            Inloggen
            <div className="ml-2">
              {loading ? <Loader size={20} /> : <ArrowRight className="size-5" />}
            </div>
          </Button>
          <Link
            href="/wachtwoord-vergeten"
            className="mx-auto pt-2 text-center text-sm text-primary"
          >
            Wachtwoord vergeten?
          </Link>
          <div className="relative flex justify-center">
            <Separator className="absolute left-0 top-1/2 z-0 w-full -translate-y-1/2" />
            <div className="relative bg-white p-2 text-sm text-muted-fg">Of nog geen account?</div>
          </div>
          <Button
            href={`/registreren${callbackUrl ? `?callbackUrl=${encodeURIComponent(callbackUrl)}` : ''}`}
            variant="outline"
            className="w-full"
          >
            Maak een account aan
          </Button>
        </form>
      </Form>
    </AuthWrapper>
  );
}
