import { Card, CardContent, CardHeader } from '@av/ui/card';
import React, { ReactNode } from 'react';

const AuthWrapper = ({
  children,
  title,
  description,
}: {
  children: ReactNode;
  title: string;
  description: string;
}) => {
  return (
    <Card className="m-auto max-w-md">
      <CardHeader className="flex flex-col items-center border-transparent py-6 text-center">
        <h2 className="mb-1 text-xl font-medium md:text-3xl">{title}</h2>
        <p className="text-sm font-normal text-muted-fg">{description}</p>
      </CardHeader>
      <CardContent className="pt-0">{children}</CardContent>
    </Card>
  );
};

export default AuthWrapper;
