'use client';

import { Input, InputProps } from '@av/ui/form';
import { cn } from '@av/utils';
import * as React from 'react';
import { FloatingLabel } from './floating-label';

const FloatingLabelInput = React.forwardRef<
  HTMLInputElement,
  InputProps & { label: string; name: string }
>(({ className, name, label, ...props }, ref) => {
  return (
    <FloatingLabel label={label} name={name}>
      <Input
        id={name}
        className={cn('peer pt-2', className)}
        ref={ref}
        placeholder=" "
        {...props}
      />
    </FloatingLabel>
  );
});
FloatingLabelInput.displayName = 'FloatingLabelInput';

export { FloatingLabelInput };
