'use client';

import { cn } from '@av/utils';
import React from 'react';
import { ClipLoader } from 'react-spinners';

export const Loader = ({ size }: { size?: number }) => {
  return <ClipLoader color="#3498db" size={size || 50} />;
};

const Loading = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & { size?: number }
>(({ className, size, ...props }, ref) => (
  <div
    className={cn('flex h-full w-full items-center justify-center', className)}
    ref={ref}
    {...props}
  >
    <ClipLoader color="#3498db" size={size || 50} />
  </div>
));
Loading.displayName = 'Loading';

export default Loading;
